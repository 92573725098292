/**
 * Группы, видимость которых зависит от других полей.
 * Возвращает массив, в котором содержится id группы
 * и массив объектов слежения (id поля и массив связанных значений)
 * @param fields {Object<[]>} - список контроллов (с бека)
 * @returns {Object<[]>}
 */
const getGroupDeps = (fields) => {
  const fls = fields;
  const links = [];
  const finder = (els) => {
    els
      .filter((el) => {
        if (el.listValues) {
          return el.listValues.some((item) => item.connectedGroups);
        }

        // для CMDB-контроллов
        if (el.cmdbItemInfoMap && Object.values(el.cmdbItemInfoMap)?.length) {
          return Object.values(el.cmdbItemInfoMap).some((item) => !!item.connectedGroups.length);
        }

        if (el.childs) finder(el.childs);

        return [];
      })
      .forEach((item) => {
        const itemValues = Object.values(item.listValues || item.cmdbItemInfoMap || {});

        itemValues
          .filter((i) => i.connectedGroups?.length)
          .forEach((value) => {
            value.connectedGroups.forEach((groupId) => {
              const existedGroup = links.find((g) => String(g.id) === String(groupId));
              if (existedGroup) {
                const existedField = existedGroup.watch.find((f) => String(f.id) === String(item.id));
                if (existedField) {
                  existedField.values.push(value.id);
                } else {
                  existedGroup.watch.push({
                    id: String(item.id),
                    values: [String(value.id)],
                  });
                }
              } else {
                links.push({
                  id: String(groupId),
                  watch: [
                    {
                      id: String(item.id),
                      values: [String(value.id)],
                    },
                  ],
                });
              }
            });
          });
      });
  };

  finder(fls);

  return links;
};

export default getGroupDeps;
