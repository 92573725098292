const createDefaultModel = (fields) => {
  const obj = {};
  fields.forEach((el) => {
    // у ресурсного поля есть defaultValue(и оно является дефолтной ролью, а не ресурсом),
    // но нет listValues
    if (el.defaultValue && el.defaultValue.name && el.class !== 'resourceField') {
      const value = el.listValues?.find(((item) => item?.name === el?.defaultValue?.name));
      if (value && 'id' in value) obj[el.id] = value.id;
    }
    // для полей для регистрации в чат-боте
    // у контрола простой текст тоже есть value, но мы такие контролы не передаем
    if (el.value && el.class !== 'text') {
      obj[el.id] = el.value;
    }
    // контактная информация
    if (el.defaultStringValue) {
      obj[el.id] = el.defaultStringValue;
    }
    if (el.class === 'counter') {
      obj[el.id] = el.minValue || 0;
    }
    if (el.class === 'graphCalendar') {
      obj[el.id] = el.viewMode === 'single' ? null : [];
    }
    if (el.class === 'scale') {
      obj[el.id] = '';
    }
    if (el.columns && el.columns.length) {
      // TODO обход первой строки таблицы
    }
    // обход групп
    if (el.childs && el.childs.length) {
      const childModel = createDefaultModel(el.childs);
      if (Object.keys(childModel).length) obj[el.id] = childModel;
    }
    // cmdb
    if (el.class === 'cmdb' && el.cmdbDefaultValueId) {
      obj[el.id] = el.cmdbDefaultValueId;
    }
    // calendar
    if (el.class === 'calendar' && el.showDateByDefault && el.defaultValue?.name) {
      obj[el.id] = el.defaultValue.name;
    }
    // input
    if (el.class === 'input' && el.defaultValue) {
      obj[el.id] = el.defaultValue;
    }
  });
  return obj;
};

export default createDefaultModel;
